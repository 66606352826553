import React, {useState, useEffect} from 'react';
import { FaCircleArrowRight } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import { Logo } from '../../App';


function Landing() {
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const handleScroll = () => {
          const scrolledToTop = window.scrollY === 0;
          setIsScrolled(!scrolledToTop);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <div className="flex flex-col min-h-screen bg-[#f5f6f5] space-y-[40px] lg:space-y-[80px]">

        {/* MENU */}
        <div className={`flex z-10 items-center sticky top-0 w-full z-50  ${isScrolled ? 'bg-[#ffffffa6] backdrop-blur-xl' : ''}`}>
            <div className={`bod2 h-[110px] flex flex-row  items-center justify-between`}>
                <Logo />
                <div className='space-x-6 text-lg font-semibold text-gray-500'>
                    <button className='hover:text-green-700' onClick={() => navigate('/login')}>Sign in</button>
                    <button className='text-green-600 hover:text-green-700' onClick={() => navigate('/register')}>Join Brand</button>
                </div>
            </div>

        </div>
        <div className='!mt-0'>
            <div className={`bod2 max-w-[1260px] px-0 ${isScrolled ? '': 'border-b-2'}`}>
            </div>
        </div>

        {/* HEADER */}
     
        <div className='bod2 2xl:py-[40px]'>
            <div className='flex flex-col lg:flex-row items-center'>
                <div className='w-full lg:w-[55%] space-y-4'>
                    <h1 className='text-gray-800 text-[55px] font-semibold leading-[75px] '>Buy, trade and grow <br /> your money</h1>
                    <p className='text-[22px] text-gray-400'>
                        Save time, reduce cost and offer a powerful and secure wallet infrastructure effortlessly.
                    </p>
                    <div className='py-2'>
                        <button className='btn4 px-[25px] py-2.5 text-xl font-semibold' onClick={() => navigate('/register')}>
                            Sign up
                        </button>
                    </div>
                </div>
                <div className='w-full lg:w-[45%] flex'>
                    <img className='' src='https://img.wirexapp.com/image/upload/q_80/dpr_auto/f_auto/v1/wirexapp/wirex50/homepage/hero/01_hero_USD_visa.png' />
                </div>
            </div>
        </div>
    
        {/* Line 1 */}
        <div className=''>
            <div className='bod2'>
                <div className='con2 flex flex-col lg:flex-row'>
                    <div className='lg:w-[40%]'>
                        <img src='https://img.wirexapp.com/image/upload/q_80/dpr_auto/f_auto/v1/wirexapp/wirex50/homepage/hero/01_hero_USD_visa.png' />
                    </div>
                    <div className='lg:w-[60%] p-8 pl-0 flex flex-col justify-around items-start'>
                        <h2 className='text-[38px] text-gray-800 font-semibold'>A Banking Alternative built for Web3</h2>
                        <p className='text-2xl'>
                            Free Account and extra secure Wirex debit card.<br />
                            It’s a simple on and off-ramp for your everyday crypto needs
                        </p>
                        <button onClick={() => navigate('/register')} className='btn42 px-[18px] py-2.5 text-xl font-semibold flex flex-row items-center space-x-2'>
                            <span>Start your crypto journe</span>
                            <FaCircleArrowRight className='text-[28px] relative top-0.5' />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        {/* Line 2 */}
        <div className=' '>
            <div className='bod2 grid grid-cols-1 lg:grid-cols-3 gap-10'>
          
                    
                    <div className='con2 lg:col-span-2 flex flex-col justify-evenly relative overflow-hidden'>

                        <h1 className='text-[38px] font-semibold max-w-[400px] z-10'>
                            Up to 8% Cryptoback™ rewards
                        </h1>
                        <p className='text-2xl max-w-[400px] text-gray-700 z-10'>
                        Earn crypto every time you use your card. 0% foreign exchange fees globally
                        </p>


                        <img src='icons5/tree.png' className='absolute bottom-0 right-0 w-[270px]' />


                    </div>

      
                <div className='con2 bg-gradient-to-tl from-[#56ca86] to-[#093c3f] min-h-[350px] relative'>
                    <span className='text-[38px] font-semibold text-white'>
                        Get exclusive <br></br> crypto <br></br> rewards
                    </span>
                    <img src='icons5/one.png' className='absolute right-[-45px] bottom-0 w-[450px] xs:w-auto' />
                </div>
            </div>
        </div>

        {/* Line 3 */}
        <div className=' '>
            <div className='bod2 grid grid-cols-1 lg:grid-cols-3 gap-10'>
                <div className='con2 bg-gradient-to-tl from-[#093c3f] to-[#56ca86] h-[450px] flex flex-col space-y-3 text-white relative'>

                    <h1 className='text-[38px] font-semibold'>
                        Generate up to 360% APR
                    </h1>

                    <p className='text-2xl'>
                        Grow your investments with DUO passive earnings Cryptoback™ Rewards
                    </p>


                    
                    <div className='absolute bottom-0'>
                        <img src='icons5/four.png' className='relative right-5' />
                    </div>






                </div>
                <div className='con2 lg:col-span-2 order-first lg:order-last flex flex-col justify-evenly relative overflow-hidden'>

                    <h1 className='text-[38px] font-semibold max-w-[400px] z-10'>
                        Build your wealth with X-Accounts
                    </h1>
                    <p className='text-2xl max-w-[400px] text-gray-700 z-10'>
                        Receive interest up to 16% Variable AER on selected currencies. It offers a secure and simple way to generate interest on account balances
                    </p>
                    <img src='icons5/two.png' className='absolute right-[-45px] bottom-0' />

                </div>
            </div>
        </div>
        
        {/* Line 4 */}
        <div className=' '>
            <div className='bod2 grid grid-cols-1 lg:grid-cols-3 gap-10 h-[350px]'>
                {
                    boxSub.map((item, i) => (
                        <div key={i} className='con2 flex flex-col items-center justify-evenly text-center'>
                            <img src={`icons5/five${i+1}.png`} className='w-[110px]' />
                            <h1 className='font-semibold text-2xl'>
                                {item.title}
                            </h1>
                            <p className='text-xl text-gray-800'>
                                {item.span}
                            </p>

                        </div>
                    ))
                }
            </div>
        </div>
        {/* Line 5 */}
        {/* <div>

        </div> */}



        {/* Line Bottom */}

        <div className={``}>
            <div className='bod2  py-[50px] border-t-2 flex flex-row'>
                
                <div className={`text-3xl font-bold flex flex-row items-center`}>
                    <Logo />
                </div>
                <div className='flex flex-col items-end w-full'>
                    <span className='font-semibold'>Copyright ©2024 Bwallet. All rights reserved.</span>
                    <div className='flex flex-row space-x-2'>
                        <Link to='/terms' className='font-semibold hover:underline'>Terms of service</Link>
                        <span>/</span>
                        <Link to='/policy' className='font-semibold hover:underline'>Privacy policy </Link>
                    </div>
                </div>
        
            </div>
        </div>



    </div>
  );
}

export default Landing;


const boxSub = [
    {
        title: 'Access institutional asset pricing',
        span: 'Buy digital assets at the same price as institutions'
    },
    {
        title: 'High limits, no fees',
        span: 'Free ATM withdrawals, unlimited spending, and no monthly fee'
    },
    {
        title: 'Bridge assets between blockcains',
        span: 'Free and instant cross-chain bridge for stablecoins and crypto '
    }
]
