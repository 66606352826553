import { useState } from "react"
import { Link } from "react-router-dom"
import HeaderLog from '../HeaderLog';
import { getData, postData } from "../../../lib/apiHandler";
import { Logo } from "../../../App";


export default function  Forgot () {
    const [level, setLevel] = useState(0)
    const [token, setToken] = useState('')

    return (
      <div className="min-h-screen flex flex-col bg-[#f5f6f5] p-5 lg:p-10">
        <Logo />
        <div className='flex flex-grow items-center justify-center'>
            {
                level === 0 && (
                    <Level0 setLevel={setLevel} setToken={setToken} />
                )
            }
            {
                level === 1 && (
                    <Level1 setLevel={setLevel} token={token} />
                )
            }
            {
                level === 2 && (
                    <Level2 setLevel={setLevel} token={token} />
                )
            }
            {
                level === 3 && (
                    <Level3 />
                )
            }
        </div>
      </div>
    );
};


const Level0 = ({setLevel, setToken}) => {
    const [errText, setErrText] = useState('')
    const [lod, setLod] = useState(false)

    const sub = async (e) => {
        e.preventDefault();
        setErrText('')
        setLod(true)

        const result = await postData('/user/forget0', {
          email: e.target.querySelector('input[name="email"]').value,
        })
        if (result.ok) {
            console.log(result.data.token)
            setToken(result.data.token)
            setLevel(1)
        } else {
          setErrText(result.data.message)
        }
    }
    return (
        <form onSubmit={sub} className="max-w-[500px] w-full con p-10 py-7  space-y-4 bg-white flex flex-col items-center justify-between text-green-600" autoComplete="off">
            {/* <img src='/ill_email.png' className='w-[110px]' /> */}
            <h1 className='text-2xl font-semibold'>
                Forgot Your Password 
            </h1>
            <span className="text-gray-600">
                Please enter the email adress you'd like your password reset inf sent to
            </span>
            <div className="[&>input]:inp space-y-3 w-full">
                <input placeholder="email" name="email" type="email" className='w-full' required />
            </div>
            <div className='flex flex-row justify-between w-full'>
                <Link to="/login" className='font-semibold hover:underline'>Back to Login </Link>
                <span className='font-semibold text-red-600'>
                    {errText}
                </span>
            </div>                    
            <button type="submit" className="btn4 text-white px-[70px] py-2 h-12 rounded-full text-xl font-semibold">Countinue</button>
        </form>
    )
}


const Level1 = ({setLevel, token}) => {
    const [errText, setErrText] = useState('')
    const [lod, setLod] = useState(false)
    
    const sub = async (e) => {
        e.preventDefault();
        setErrText('')
        setLod(true)

        const result = await postData('/user/forget1', {
            code: e.target.querySelector('input[name="code"]').value,
            token: token
        })
        if (result.ok) {
            setLevel(2)
        } else {
          setErrText(result.data.message)
        }
    }

    return (
        <form onSubmit={sub} className="max-w-[500px] w-full con p-10 py-7  space-y-4 bg-white flex flex-col items-center justify-between text-green-600" autoComplete="off">
            {/* <img src='/ill_email.png' className='w-[110px]' /> */}
            <h1 className='text-2xl font-semibold'>
                Code sent
            </h1>
            <span className="text-gray-600">
                Please enter the code you received in your email
            </span>
            <div className="[&>input]:inp space-y-3 w-full">
                <input placeholder="code" name="code" type="text" className='w-full' required />
            </div>
            <div className='flex flex-row justify-between w-full'>
                <Link to="/login" className='font-semibold hover:underline'>Back to Login </Link>
                <span className='font-semibold text-red-600'>
                    {errText}
                </span>
            </div>                    
            <button type="submit" className="btn4 text-white px-[70px] py-2 h-12 rounded-full text-xl font-semibold">Reset</button>
        </form>
    )
}




const Level2 = ({setLevel, token}) => {
    const [errText, setErrText] = useState('')
    const [lod, setLod] = useState(false)


    const sub = async (e) => {
        e.preventDefault();
        setErrText('')
        setLod(true)

        const result = await postData('/user/forget2', {
            password1: e.target.querySelector('input[name="password1"]').value,
            password2: e.target.querySelector('input[name="password2"]').value,
            token: token,
        })
        if (result.ok) {
            setLevel(3)
        } else {
          setErrText(result.data.message)
        }
    }

    return (
        <form onSubmit={sub} className="max-w-[500px] w-full con p-10 py-7  space-y-4 bg-white flex flex-col items-center justify-between text-green-600" autoComplete="off">
            {/* <img src='/ill_email.png' className='w-[110px]' /> */}
            <h1 className='text-2xl font-semibold'>
                Reset Your Password 
            </h1>
            <div className="[&>input]:inp space-y-3 w-full">
                <input placeholder="password" name="password1" type="password" className='w-full' required />
                <input placeholder="Confirm Password" name="password2" type="password" className='w-full' required />
            </div>
            <div className='flex flex-row justify-between w-full'>
                <Link to="/login" className='font-semibold hover:underline'>Back to Login </Link>
                <span className='font-semibold text-red-600'>
                    {errText}
                </span>
            </div>                    
            <button type="submit" className="btn4 text-white px-[70px] py-2 h-12 rounded-full text-xl font-semibold">Reset</button>
        </form>
    )
}




const Level3 = () => {

    return (
        <div className="max-w-[500px] w-full con p-10 py-7  space-y-8 bg-white flex flex-col items-center justify-between text-[#071278] text-center">
            <h1 className="text-3xl font-semibold w-full max-w-[400px]">
            The password has been changed successfully
            </h1>
                <div className="flex justify-center text-lg w-full flex flex-col w-full max-w-[500px]">
                    <span className="">You are welcome to return to the login page, and make a new login</span>
                </div>
                <div className="flex justify-center text-lg w-full flex flex-col w-full max-w-[500px]">
                    <Link to="/login" className="font-semibold hover:underline text-[#071278]">Click here</Link>
                </div>
            
        </div>
    )
}