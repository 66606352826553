import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation  } from 'react-router-dom';
import { AuthProvider, useAuth } from './lib/AuthContext';


//////////// Handler ///////////

import {Landing, Login, Register, changeTitle, MenuTerms, Logo, BrandName, Forgot} from './lib/handlerstage/Site3';

////////////


import TermsPage from './pages/terms/Terms'
import PolicyPage from './pages/terms/Policy';


import Layout from './pages/user/Layout';
import Home from './pages/user/Home';
import Coins from './pages/user/Coins';

import Panel from './pages/admin/Panel';
import PanelLayout from './pages/admin/PanelLayout';
import UserPage from './pages/admin/UserPage2';

import { useEffect, useState } from 'react';
import LoadingPage from './pages/general/LoadingPage';
import { useRecoilState } from 'recoil';
import { notiList0, messages0, currentUser0, loc0 } from './lib/states';
import Address from './components/adminParts/Address';
import Test from './components/parts/PrefixInp';


function App() {
  useEffect(() => {
    changeTitle()
  })


  const [notiList, setNotiList] = useRecoilState(notiList0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotiList(prevValue => prevValue.slice(1))
    }, 5000);
    return () => clearTimeout(timer);
  }, [notiList])



    // useEffect(() => {
    //   const link = document.createElement('link');
    //   link.rel = 'icon';
    //   link.href = '/icons3/fav21.png';
    //   document.head.appendChild(link);

    //   return () => {
    //     document.head.removeChild(link);
    //   };
    // }, []);


  return (
    <AuthProvider>
      <Router>
        <Routes>

          <Route path="/" 
            element={<AuthRoute restricted={true} component={<Landing />} />} />


          <Route path="/login" 
            element={<AuthRoute restricted={true} component={<Login />} />} />
          <Route path="/register" 
            element={<AuthRoute restricted={true} component={<Register />} />} />
          <Route path="/forgot" 
            element={<AuthRoute restricted={true} component={<Forgot />} />} />
          
          <Route path="/terms" 
            element={<AuthRoute restricted={true} component={<TermsPage />} />} />

          <Route path="/policy" 
            element={<AuthRoute restricted={true} component={<PolicyPage />} />} />
  

          <Route
            path="/home"
            element={<PrivateRoute component={<Home />} />}
          />
          <Route
            path="/coins/:coinId"
            element={<PrivateRoute component={<Coins />} />}
          />


          <Route
            path="/panel"
            element={<AdminRoute component={<Panel />} />}
          />
          <Route
            path="/panel/address"
            element={<AdminRoute component={<Address />} />}
          />
          <Route
            path="/panel/user/:userId"
            element={<AdminRoute component={<UserPage />} />}
          />


          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

const AdminRoute = ({ component }) => {
  const { isAdmin } = useAuth();
  const { isLoggedIn } = useAuth();

  if (isLoggedIn !== null && isAdmin !== null) {
    if (isLoggedIn && isAdmin) {
      return (
        <PanelLayout>
          {component}
        </PanelLayout>
      )
    } else {
      return <Navigate to="/" replace state={{ from: window.location.pathname }} />
    }
    
  } else {
    return <LoadingPage />
  }
};

const PrivateRoute = ({ component }) => {

  const { isLoggedIn } = useAuth();
  if (isLoggedIn !== null) {
    return isLoggedIn ? (
      <Layout>
        {component}
      </Layout>
    ) : (
      <Navigate to="/login" replace state={{ from: window.location.pathname }} />
    );
  } else {
    <LoadingPage />
  }
};

const AuthRoute = ({ component, restricted }) => {
  const [loc, setLoc] = useRecoilState(loc0)
  const location = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    if (loc === null) {
      setLoc(location.pathname)
    }
  }, [])



  const { isLoggedIn } = useAuth();
  const { isAdmin } = useAuth();



  if (location.pathname === '/register' && loc === null) {
    return <Navigate to="/login" />
  } else if (isLoggedIn) {
    if (isAdmin) return <Navigate to="/home" /> // /panel
    else return <Navigate to="/home" />
  } else if (!isLoggedIn) {
    return component
  } else {
    return <LoadingPage />
  }

};



export default App;

export {MenuTerms, Logo, BrandName};