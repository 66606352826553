

import Landing from "../../pages/lands/Landing3";
import Login from '../../pages/general/logins/Login3';
import Register from '../../pages/general/registers/register3';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Forgot from '../../pages/general/forgots/Forgot3'

// bwallet.live
const BrandName = 'Bwallet';
const changeTitle = () => {
    document.title = "Bwallet";
  }

const MenuTerms = () => {
    const navigate = useNavigate()
    const [isScrolled, setIsScrolled] = useState(false);

    return (
        <>
            <div className={`flex z-10 items-center sticky top-0 w-full  ${true ? 'bg-[#ffffffa6] backdrop-blur-xl' : ''}`}>
                <div className={`bod2 h-[110px] flex flex-row  items-center justify-between`}>
                    <span className='cursor-pointer' onClick={() => navigate('/')}>
                        <Logo />
                    </span>
                    <div className='space-x-6 text-lg font-semibold text-gray-500'>
                        <button className='hover:text-green-700' onClick={() => navigate('/login')}>Sign in</button>
                        <button className='text-green-600 hover:text-green-700' onClick={() => navigate('/register')}>Join Brand</button>
                    </div>
                </div>

            </div>
            <div className='!mt-0'>
                <div className={`bod2 max-w-[1260px] px-0 ${isScrolled ? '': 'border-b-2'}`}>
                </div>
            </div>
        </>
    )
}

const Logo = ({color}) => {
    const navigate = useNavigate()
    return (
        <div className={`text-[26px] font-bold ${color} flex flex-row items-center cursor-pointer`} onClick={() => navigate('/')}>
            <img className="bg-white rounded-full w-10 mr-1" src="/favicons/fav3.ico" />
            <span className="relative bottom-[2px]">wallet</span>
        </div>
    )
}



export {Landing, Login, Register, MenuTerms, changeTitle, Logo, Forgot, BrandName}